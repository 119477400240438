<template>
  <div style="width:100%;float:left;">
    <div class="col-6 m-auto pt-2">
      <CCard class="p-4">
        <CCardBody>
          <CAlert
            :show.sync="showMessage"
            color="primary"
            fade
          >
            {{ message }}
          </CAlert>
          <CForm @submit.prevent="submit" method="POST">
            <h1>Login</h1>
            <p class="text-muted">Email to reset password.</p>
            <CInput
              v-model="email"
              prependHtml="<i class='cui-user'></i>"
              placeholder="Email"
              autocomplete="username email"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>
            <CRow>
              <CCol col="6" class="text-left">
                <CButton type="submit" :disabled="submitted" color="success" class="px-4">Submit</CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>

import axios from "axios";

    export default {
      name: 'Login',
      data() {
        return {
          email: '',
          password: '',
          showMessage: false,
          message: '',
          dismissCountDown: 5,
          submitted: false
        }
      },
      methods: {
        goRegister(){
          this.$router.push({ path: 'register' });
        },
        submit() {
          let self = this;
          console.log('login')
          this.submitted = true
          axios.post( this.$apiAdress + '/api/resetPasswordRequest', {
            email: self.email,
          }).then(response => {
            console.log('success')
            console.log(response)
            self.message = 'You have been sent an email to reset your password.'
            self.showMessage = true;
            this.submitted = false
          }).catch(e => {
            self.message = 'We do not have an email address with what you entered.';
            self.showMessage = true;
            this.submitted = false
            console.log(error);
          });
  
        }
      }
    }

</script>
